import React from 'react';

import { graphql, Link } from 'gatsby';

import Img from 'gatsby-image';

import Layout from 'components/layout';
import SEO from 'components/seo';
import FeaturedBlog from 'components/Blog/FeaturedBlog';
import Blog from 'components/Blog/Blog';

import SafeAreaWrapper from '../components/SafeAreaWrapper';

import { Primary as PrimaryButton } from 'components/Button';

import Pagination from 'components/Pagination';
import Hero from 'components/Hero';

import CategoryLabel from 'components/Blog/CategoryLabel';
import {
	ArticleSnippet,
	ArticleSnippetFeatured,
	ArticleSnippetSmall,
} from 'components/Blog/ArticleSnippet';

// const big = false;

// const AnimatedHero = animated(styled(Hero)`
// 	${'' /* ${tw`h-500 md:h-550 ${big ? 'xl:h-730' : 'xl:h-600'}`} */}
// `);

const IndexPage = ({
	location,
	pageContext,
	data: {
		blogs: { edges },
		heroBackgroundImage,
		productPortfolioImage,
		upcomingEvents,
	},
}) => {
	return (
		<Layout translucent="true">
			<SEO title="New Features" description="" />

			<Hero backgroundImage={heroBackgroundImage} location={location}>
				<div
					className="flex flex-col items-center justify-center h-full"
					style={{ backgroundColor: 'rgba(0, 70, 121, .6)' }}>
					<h1 className="text-center text-white">New features</h1>
					<h1 className="text-center" style={{ color: '#B6C3CF' }}>
						Latest updates and additions
					</h1>
				</div>
			</Hero>

			<SafeAreaWrapper>
				<div className="container mx-auto px-20 md:px-0 py-120  pb-80 md:pb-100 lg:pb-120">
					<div className="grid grid-cols-12 md:gap-x-40 gap-y-60">
						{edges.map(
							(
								{
									node: {
										fields: {
											readingTime: { text: timeToRead },
										},
										frontmatter: {
											title,
											excerpt,
											slug,
											category,
											featureImage,
											date,
										},
									},
								},
								index,
							) => (
								<div className="col-span-12 md:col-span-6 lg:col-span-4">
									<Link to={`/blog/${slug}`}>
										<div className="mb-20">
											{featureImage && (
												<div className="rounded overflow-hidden h-200 md:h-260 lg:h-240 relative z-10">
													<Img
														fluid={featureImage.childImageSharp.fluid}
														className="rounded w-full h-full object-cover duration-500 transform hover:scale-125 z-0"
													/>
												</div>
											)}
										</div>

										<div className="">
											<ArticleSnippet
												category={category}
												date={date}
												title={title}
												excerpt={excerpt}
												timeToRead={timeToRead}
											/>
										</div>
									</Link>
								</div>
							),
						)}
					</div>
					<div className="mt-40 md:mt-50 xl:60 mb-80 md:mb-0">
						<Pagination urlPrefix="/new-features" pageContext={pageContext} />
					</div>
				</div>
			</SafeAreaWrapper>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query NewFeaturesIndex($skip: Int! = 0, $limit: Int! = 10) {
		heroBackgroundImage: file(relativePath: { eq: "new-features-background.png" }) {
			childImageSharp {
				fluid(quality: 60, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}

		blogs: allMdx(
			filter: {
				isFuture: { eq: false }
				frontmatter: { tags: { in: "feature" }, published: { eq: true } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			skip: $skip
			limit: $limit
		) {
			edges {
				node {
					id
					fields {
						readingTime {
							text
						}
					}
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						excerpt
						slug
						title
						category
						featureImage {
							childImageSharp {
								fluid(maxWidth: 840, quality: 60) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`;
